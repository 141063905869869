<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
      <el-form-item label="表名称" prop="tableName">
        <el-input
          v-model="queryParams.tableName"
          placeholder="请输入表名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表描述" prop="tableComment">
        <el-input
          v-model="queryParams.tableComment"
          placeholder="请输入表描述"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
            ref="multipleTable"
            :data="tableList"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            @selection-change="handleSelectionChange">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column
        label="表名称"
        align="center"
        prop="tableName"
        width="200"
      />
      <el-table-column
        label="表描述"
        align="center"
        prop="tableComment"
        width="200"
      />
      <el-table-column label="创建时间" align="center" prop="createTime" width="160" />
      <el-table-column label="更新时间" align="center" prop="updateTime" width="160" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
                  type="primary" plain
            @click="handleEditTable(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="current"
            :page-size="size"
            :total="total">
    </el-pagination>
    <update-table-column ref="updateTableColumn" v-if="updateTableColumnVisible" @refreshDataList="getList" ></update-table-column>
  </div>
</template>

<script>
  import updateTableColumn from "./updateTableColumn";
export default {
  name: "Gen",
  components:{
    updateTableColumn
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      multipleSelection:[],
      // 唯一标识符
      uniqueId: "",
      size: 10,
      current: 1,
      // 总条数
      total: 0,
      // 表数据
      tableList: [],
      // 日期范围
      dateRange: "",
      updateTableColumnVisible:false,
      // 查询参数
      queryParams: {
        tableName: undefined,
        tableComment: undefined
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      this.size = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.current = val
      this.getList();
    },

    /** 查询表集合 */
    getList() {
      this.loading = true;
      this.queryParams.pageNo=this.current
      this.queryParams.pageSize=this.size
      this.$axios.post('/admin/acquisitiontask/genList', this.queryParams).then(response => {
        this.tableList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 修改按钮操作 */
    handleEditTable(row) {
      this.updateTableColumnVisible=true
      this.$nextTick(()=>{
        this.$refs.updateTableColumn.init(row.tableName);
      })
    },
  }
};
</script>
